import { useLazyQuery, useMutation } from "@apollo/client"
import { Alert } from "antd"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import KashVaultNoYearLogo from "../../assets/images/KashVaultNoYearLogo.png"
import TireProgramLogo from "../../assets/images/TireProgramLogo.png"
import WebastoProgramLogo from "../../assets/images/WebastoProgramLogo.png"
import WebastoTileImage from "../../assets/images/WebastoTileImage.png"
import KiaKashTileImage from "../../assets/images/KiaKashTileImage.png"
import TireSourceTileImage from "../../assets/images/TireSourceTileImage.png"
import KiaWindshieldTileImage from "../../assets/images/KiaWindshieldTileImage.png"
import KiaWindshieldLogo from "../../assets/images/KiaWindshieldLogo.png"
import Footer from "../../components/Layout/Footer"
import Header from "../../components/Layout/Header"
import Container from "../../components/styled/Container.styled"
import { errorTimeout } from "../../constants/general"
import { CommonPaths } from "../../constants/paths"
import RESET_ALERT from "../../constants/resetAlert"
import { useAuthStatus } from "../../hooks/useAuthStatus"
import useGetMyPermissions from "../../hooks/useGetMyPermissions"
import { login, setTokenTimeout } from "../../redux/auth/authSlice"
import { useAppDispatch } from "../../redux/hooks"
import { setAuthToken, setSessionId } from "../../utils/auth"
import Spinner from "../Spinner"
import { UnknownCustomerRedirect } from "./UnknownCustomerRedirect"
import { REQUEST_SSO } from "./graphql"
import { ProgramButton } from "./styled"
import { ProgramTile } from "./styled"
import { ProgramDetails } from "./styled"
import { KIAKASH_CHECK_ELIGIBILITY } from "../EnrollmentForm/graphql"

const CustomerSignIn = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { loggedIn } = useAuthStatus()

  const state = location.state as { from: Location }
  const from = state ? state.from.pathname : CommonPaths.HOME_PATH

  // Consumer Side - tokens from URL
  const token = searchParams.get("token")
  const refreshToken = searchParams.get("refreshToken")
  const tokenTime = searchParams.get("tokenTime")
  const sessionId = searchParams.get("session")

  const { loading: loadingPermissions } = useGetMyPermissions()

  const [alert, setAlert] = useState(RESET_ALERT)

  const [loginOtherProgram, { loading: loggingIn }] = useMutation<{
    requestSingleSignOn: string
  }>(REQUEST_SSO, {
    onCompleted(data) {
      window.location.replace(data.requestSingleSignOn)
    },
    onError(error) {
      setAlert({
        type: "error",
        message: error.message,
      })
    },
  })

  const [kiaKashContinueToProgram] = useLazyQuery(KIAKASH_CHECK_ELIGIBILITY)

  // Resetting Alert
  useEffect(() => {
    if (alert.message) {
      setTimeout(() => {
        setAlert(RESET_ALERT)
      }, errorTimeout)
    }
  }, [alert.message])

  useEffect(() => {
    if (loggedIn && !loadingPermissions) {
      navigate(from, { replace: true })
    }
  }, [from, navigate, loggedIn, loadingPermissions])

  // Login util
  function onLogin({
    refreshToken,
    token,
    tokenTime,
  }: {
    token: string
    refreshToken: string
    tokenTime: string
  }) {
    setAuthToken({
      token,
      refresh_token: refreshToken,
    })
    if (sessionId) {
      setSessionId(sessionId)
    }
    dispatch(setTokenTimeout(tokenTime))
    dispatch(login())
  }

  // Kia Kash login function
  function onKiaKashLogin() {
    kiaKashContinueToProgram({
      variables: { token },
      onCompleted(data) {
        if (data.checkJobTitleEligibility) {
          if (refreshToken && token && tokenTime) {
            onLogin({ refreshToken, token, tokenTime })
          }
        }
      },
      onError(error) {
        setAlert({
          type: "error",
          message: error.message,
        })
      },
    })
  }

  // Kia Tire login function
  function onKiaTireLogin() {
    if (refreshToken && token && tokenTime) {
      loginOtherProgram({
        variables: {
          programCode: "KIA_TIRE",
          token,
        },
      })
    }
  }

  // Webasto login function
  function onWebastoLogin() {
    if (refreshToken && token && tokenTime) {
      loginOtherProgram({
        variables: {
          programCode: "WEBASTO",
          token,
        },
      })
    }
  }

  // Kia Windshield login function
  function onKiaWindshieldLogin() {
    if (refreshToken && token && tokenTime) {
      loginOtherProgram({
        variables: {
          programCode: "KIA_VWBKV",
          token,
        },
      })
    }
  }

  // If token does not exist
  if (!loggedIn && (!token || !refreshToken || !tokenTime)) {
    return <UnknownCustomerRedirect />
  }

  return (
    <>
      {/* <Header showNone /> */}
      <div className="container-fluid pb-5">
        <div className="row text-center bg-dark-subtle py-4">
          <div className="col">
            <img
              src={KashVaultNoYearLogo}
              width="120px"
              alt="Kia Kash Vault"
              className="pb-3"
            />
            <h5 className="text-center pt-4 border-top">Welcome to the</h5>
            <h1 className="display-4 fw-bold">Kash Vault Programs</h1>
            <p>
              These programs give you exciting opportunities to earn KASH!
              <br />
              Select a program to make Sales Claims and earn KASH on a prepaid
              Visa Reward Card.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-3">
          <div className="col-md-3 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={KiaKashTileImage}
                  width="100%"
                  alt="Kia Kash Vault Products"
                />
                <img
                  src={KashVaultNoYearLogo}
                  width="40%"
                  alt="Kia Kash Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Kash Vault
                <div>Eligible Product(s)</div>
                Kia Genuine Accessories
                <div>Eligible Participants</div>
                Dealer Parts & Service employees
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaKashLogin}
                  type="button"
                  disabled={loggingIn}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>

          <div className="col-md-3 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={TireSourceTileImage}
                  width="100%"
                  alt="Tire Source Products"
                />
                <img
                  src={TireProgramLogo}
                  width="40%"
                  alt="Kia Tire Source Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Tire Source Vault
                <div>Eligible Product(s)</div>
                Kia Tires
                <div>Eligible Participants</div>
                Dealer Service Consultants
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaTireLogin}
                  type="button"
                  disabled={loggingIn}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>

          <div className="col-md-3 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={WebastoTileImage}
                  width="100%"
                  alt="Webasto Products"
                />
                <img
                  src={WebastoProgramLogo}
                  width="40%"
                  alt="Webasto Kash Vault"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Webasto Kash Vault
                <div>Eligible Product(s)</div>
                Go Dual Voltage Portable Wall Charger
                <div>Eligible Participants</div>
                F&I Managers, Parts Managers, Parts Specialists, Sales
                Consultants, Service Managers, Service Advisors, Service
                Consultants
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onWebastoLogin}
                  type="button"
                  disabled={loggingIn}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>

          <div className="col-md-3 py-4">
            <ProgramTile className="card">
              <div className="text-center">
                <img
                  src={KiaWindshieldTileImage}
                  width="100%"
                  alt="Kia Windshield Wiper Products"
                />
                <img
                  src={KiaWindshieldLogo}
                  width="50%"
                  alt="Kia Windshield Wiper Contest"
                />
              </div>

              <ProgramDetails className="card-body">
                <div>Program Name</div>
                Kia Windshield Wiper Contest
                <div>Eligible Product(s)</div>
                Conventional Wiper Blades, Rear Wiper Blades, Flat Wiper Blades
                <div>Eligible Participants</div>
                Parts Manager
                <div>Promotion Period</div>
                05/01/2024 - 06/30/2024
              </ProgramDetails>

              <div className="card-footer">
                <ProgramButton
                  onClick={onKiaWindshieldLogin}
                  type="button"
                  disabled={loggingIn}
                  className="btn"
                >
                  Continue To Program
                </ProgramButton>
              </div>
            </ProgramTile>
          </div>
        </div>

        {loggingIn && (
          <div className="text-center">
            <Spinner />
            <p>Redirecting...</p>
          </div>
        )}

        {alert.message && <Alert {...alert} />}
      </div>
      <Footer />
    </>
  )
}

export { CustomerSignIn }
