import { gql } from "@apollo/client"

// To request enrollment link for other programs
const REQUEST_ENROLLMENT_LINK = gql`
  mutation RequestSingleSignOn(
    $programCode: String!
    $token: String!
    $isNewEnrolment: Boolean
  ) {
    requestSingleSignOn(
      programCode: $programCode
      token: $token
      isNewEnrolment: $isNewEnrolment
    )
  }
`

const KIAKASH_CHECK_ELIGIBILITY = gql`
  query Query($token: String!) {
    checkJobTitleEligibility(token: $token)
  }
`

export { REQUEST_ENROLLMENT_LINK, KIAKASH_CHECK_ELIGIBILITY }
