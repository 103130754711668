import { useMutation } from "@apollo/client"
import { AutoComplete, Form, FormInstance } from "antd"
import { useEffect, useState } from "react"
import { AUTOCOMPLETE_ADDRESS } from "../../graphql/mutations"
import useDebouncedValue from "../../hooks/useDebouncedValue"
import { IAutoCompleteAddressResponse } from "../../types/graphqlResponse"
import Spinner from "../Spinner"
import { validateLeadingTrailingSpaces } from "../../utils/formUtils"

type IOption = {
  value: string
  label: string
  id: number
}

type Props = {
  form: FormInstance
  /** This accepts Address 2, City, State, ZIP code Form.Item names
   * to update other form fields with the corresponding names.
   * Make sure names are in order.
   */
  formItemNamesToUpdate: string[]
  name: string
  label: string
  /** If the message exists, this field becomes required */
  requiredMessage?: string
  disabled?: boolean
}

const DealerAddressAutocomplete = ({
  form,
  formItemNamesToUpdate,
  name,
  label,
  requiredMessage,
  disabled = false,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [options, setOptions] = useState<IOption[] | []>([])

  const { debouncedValue, debouncing } = useDebouncedValue(searchTerm)

  const [autoCompleteAddress, { data, loading }] =
    useMutation<IAutoCompleteAddressResponse>(AUTOCOMPLETE_ADDRESS, {
      variables: {
        dealerStreetAddress: debouncedValue,
      },
      onCompleted(data) {
        setOptions(getOptionsFromData(data))
      },
    })

  useEffect(() => {
    if (debouncedValue.length > 2) {
      autoCompleteAddress()
    }
    if (debouncedValue.length < 2) {
      setOptions([])
    }
  }, [debouncedValue, autoCompleteAddress])

  /** Function to update the corresponding address fields */
  const setFormValues = (id: number) => {
    const selectedAddress = data?.autoCompleteAddress[id]
    const name = formItemNamesToUpdate // Just to shorten

    form.setFieldsValue({
      [name[0]]: selectedAddress?.secondary,
      [name[1]]: selectedAddress?.dealerCity,
      [name[2]]: selectedAddress?.dealerState,
      [name[3]]: selectedAddress?.dealerZip,
    })
    form.validateFields(name)
  }

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        { required: !!requiredMessage, message: requiredMessage },
        validateLeadingTrailingSpaces(),
      ]}
    >
      <AutoComplete
        onSearch={(term) => setSearchTerm(term)}
        options={options}
        virtual={false}
        allowClear
        onClear={() => setOptions([])}
        onSelect={(_: string, option: IOption) => setFormValues(option.id)}
        notFoundContent={loading || debouncing ? <Spinner /> : "No Suggestions"}
        disabled={disabled}
        // @ts-ignore
        autoComplete="new-password"
      />
    </Form.Item>
  )
}

const getOptionsFromData = (data: IAutoCompleteAddressResponse): IOption[] => {
  return data.autoCompleteAddress.map((item, index) => {
    const label = `${item.dealerStreetAddress}, ${item.dealerCity}, ${item.dealerState} - ${item.dealerZip}`

    return {
      value: item.dealerStreetAddress,
      label,
      id: index,
      key: index,
    }
  })
}

export default DealerAddressAutocomplete
